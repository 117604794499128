import React from "react";
import { Container } from "reactstrap";
import ServicesIMG from "../assets/images/services.png";
import CTA from "../assets/images/cover.png";
import Map from "../assets/images/map.png";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";

export default function Services() {
  const { t } = useTranslation();

  return (
    <>
      {/* CTA Start */}
      <section
        className="section pb-5"
        style={{
          background: `url(${CTA}) center`,
          backgroundSize: "cover",
          backgroungRepeat: "no-repeat",
        }}
      >
        <div className="bg-overlay"></div>
        <Container>
          <a
            href="https://gruporuralmanager.com"
            target="_blank"
            rel="noreferrer"
          >
            <div className="row justify-content-center">
              <div className="col">
                <div className="section-title text-center">
                  <h4 className="title text-white mb-3">{t("cta.text1")}</h4>
                  <h4 className="title text-white mb-3">{t("cta.text2")}</h4>
                </div>
              </div>
            </div>
          </a>
        </Container>
      </section>
    </>
  );
}
