import React, { useState } from "react";
import { Col, Row, CardBody, Card } from "reactstrap";
import AboutIMG from "../assets/images/about.jpg";
import Map from "../assets/images/map.png";
import User from "../assets/images/user.png";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";
import Team1Img from "../assets/images/team1.png";
import Team2Img from "../assets/images/team2.png";
import Team3Img from "../assets/images/team3.png";
import Team4Img from "../assets/images/team4.png";
import Team5Img from "../assets/images/team5.png";
import Team6Img from "../assets/images/team6.png";

import { Link as Link2 } from "react-router-dom";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function About() {
  const { t } = useTranslation();

  const [videoModal, setModal] = useState(false);

  const team = [
    {
      name: t("about.team1"),
      profile: Team1Img,
      department: t("about.team1Sub"),
      url: "https://www.linkedin.com/in/juan-carlos-p%C3%A9rez-rodr%C3%ADguez-7783141a3",
    },
    {
      name: t("about.team2"),
      profile: Team2Img,
      department: t("about.team2Sub"),
      url: "https://www.linkedin.com/in/juanmartinrodriguez/",
    },
    {
      name: t("about.team5"),
      profile: Team5Img,
      department: t("about.team5Sub"),
      url: "https://www.linkedin.com/in/rocioperezcolombo/",
    },
    {
      name: t("about.team3"),
      profile: Team3Img,
      department: t("about.team3Sub"),
      url: "https://www.linkedin.com/in/miguegilmartip",
    },
    {
      name: t("about.team4"),
      profile: Team4Img,
      department: t("about.team4Sub"),
      url: "https://www.linkedin.com/in/mar%C3%ADa-valc%C3%A1rcel-tabernero-b6658b229",
    },
    {
      name: t("about.team6"),
      profile: Team6Img,
      department: t("about.team6Sub"),
      url: "https://www.linkedin.com/in/isabelsancheztejado?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    },
  ];

  return (
    <>
      <section className="section bg-light" id="about">
        <div className="container mt-10">
          <div style={{ background: `url(${Map}) center center` }}>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="position-relative me-lg-5">
                  <img
                    src={AboutIMG}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                  <div className="play-icon">
                    <Link2
                      to="#"
                      onClick={() => setModal(true)}
                      data-type="youtube"
                      data-id="yba7hPeTSjk"
                      className="play-btn lightbox"
                    >
                      <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                    </Link2>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title">
                  <h4 className="title mb-3 main-color">
                    {t("about.mainTitle")}
                  </h4>
                  <h5 className="mb-3">{t("about.mainSubtitle")}</h5>
                  <p className="text-muted">{t("about.text1")}</p>
                  <p className="text-muted">{t("about.text2")}</p>
                  <p className="text-muted">{t("about.text3")}</p>
                  <p className="text-muted">{t("about.text4")}</p>
                </div>
              </div>
            </div>
          </div>

          <Row className="justify-content-center pt-5">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2 pt-5 mt-3">
                <h4 className="title mb-3">{t("about.ourTeam")}</h4>
                <p className="para-desc mx-auto text-muted mb-0">
                  {t("about.ourTeamSubtitle")}
                </p>
              </div>
            </div>
          </Row>

          <Row className="d-flex justify-content-center">
            {team.map((item, key) => (
              <Col lg={4} md={6} className="mt-4 pt-2" key={key}>
                <Card
                  className="border-0 text-center shadow border-0 overflow-hidden rounded"
                  style={{ height: "100%" }}
                >
                  <img
                    src={item.profile}
                    className="img-fluid"
                    alt=""
                    style={{ objectFit: "cover" }}
                  />
                  <CardBody
                    className="d-flex flex-column justify-content-between"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                    }}
                  >
                    <h5
                      className="mb-1"
                      style={{
                        minHeight: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.name}
                    </h5>
                    <small
                      className="text-muted"
                      style={{
                        minHeight: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {item.department}
                    </small>
                    <div
                      className="card-link-container"
                      style={{
                        minHeight: "40px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <i
                          className="uil uil-linkedin align-middle"
                          title="LinkedIn"
                        ></i>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>
      <ModalVideo
        channel="youtube"
        isOpen={videoModal}
        videoId="cD5BSAwWrAY"
        onClose={() => setModal(false)}
      />
    </>
  );
}
