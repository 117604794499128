import React from "react";
import { Col, Row, Container } from "reactstrap";
import { useTranslation } from "react-i18next";

import Partner01 from "../assets/images/partners/partners01.svg";
import Partner02 from "../assets/images/partners/partners02.png";
import Partner03 from "../assets/images/partners/partners03.png";
import Partner04 from "../assets/images/partners/partners04.png";
import Partner05 from "../assets/images/partners/partners05.png";
import Partner07 from "../assets/images/partners/partners07.png";
import Partner08 from "../assets/images/partners/partners08.png";
import Partner09 from "../assets/images/partners/partners09.png";
import Partner10 from "../assets/images/partners/partners10.png";
import Partner11 from "../assets/images/partners/partners11.png";
import Partner12 from "../assets/images/partners/partners12.png";
import Partner13 from "../assets/images/partners/partners13.png";
import Partner14 from "../assets/images/partners/partners14.png";
import Partner15 from "../assets/images/partners/partners15.png";
import Partner16 from "../assets/images/partners/partners16.png";
import Partner17 from "../assets/images/partners/partners17.png";
import Partner18 from "../assets/images/partners/partners18.png";

export default function About() {
  const { t } = useTranslation();

  const partners = [
    Partner01,
    Partner02,
    Partner03,
    Partner04,
    Partner05,
    Partner07,
    Partner08,
    Partner09,
    Partner10,
    Partner11,
    Partner12,
    Partner13,
    Partner14,
    Partner15,
    Partner16,
    Partner17,
    Partner18,
  ];

  const partnerStyle = {
    maxWidth: "150px",
    maxHeight: "100px",
    margin: "10px",
    transition: "filter 0.3s ease",
    filter: "grayscale(100%)",
  };

  const partnerHoverStyle = {
    filter: "none",
  };

  return (
    <>
      <section className="section" id="partners">
        <Container>
          <Row className="justify-content-center pb-5">
            <Col>
              <div className="section-title text-center mb-4 pb-2">
                <h5 className="title mb-3">{t("footer.partners")}</h5>
                <p className="text-muted mb-3">
                  {t("footer.org")} - {t("footer.companies")} -{" "}
                  {t("footer.foundations")}
                </p>

                {/* Partners Logos */}
                <Row className="justify-content-center pt-5 mt-3">
                  {partners.map((partner, index) => (
                    <Col
                      key={index}
                      xs="6"
                      sm="4"
                      md="3"
                      lg="2"
                      className="text-center"
                    >
                      <img
                        src={partner}
                        alt={`Partner ${index + 1}`}
                        style={partnerStyle}
                        onMouseEnter={(e) => {
                          e.target.style.filter = partnerHoverStyle.filter;
                        }}
                        onMouseLeave={(e) => {
                          e.target.style.filter = partnerStyle.filter;
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
