import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import CountUp from "react-countup";
import Map from "../assets/images/map.png";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";

import Mockup from "../assets/images/mockup-app.png";
import App1 from "../assets/images/app-img1.png";
import App2 from "../assets/images/app-img2.png";
import App3 from "../assets/images/app-img3.png";
import App4 from "../assets/images/app-img4.png";
import App5 from "../assets/images/app-img5.png";
import App6 from "../assets/images/app-img6.png";
import App7 from "../assets/images/app-img7.png";
import App8 from "../assets/images/app-img8.png";
import App9 from "../assets/images/app-img9.png";
import App10 from "../assets/images/app-img10.png";

import SVG1 from "../assets/images/search.svg";
import SVG2 from "../assets/images/swipe.svg";
import SVG3 from "../assets/images/heart.svg";

import SwiperCore, { Pagination, Autoplay } from "swiper";
import "../../node_modules/swiper/swiper.scss";
import "../../node_modules/swiper/components/navigation/navigation.scss";
import "../../node_modules/swiper/components/pagination/pagination.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import axios from "axios";

import { Link as Link2 } from "react-router-dom";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

SwiperCore.use([Pagination, Autoplay]);

export default function App() {
  const { t } = useTranslation();
  const [videoModal, setModal] = useState(false);

  const swiperParams = {
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    pagination: {
      el: "#appshot-paginations",
      type: "bullets",
      clickable: "true",
    },
    slidesPerView: 5,
    // Responsive breakpoints
    breakpoints: {
      0: {
        slidesPerGroup: 2,
        slidesPerView: 2,
      },
      767: {
        slidesPerGroup: 3,
        slidesPerView: 3,
      },
      991: {
        slidesPerGroup: 2,
        slidesPerView: 3,
      },
      1499: {
        slidesPerGroup: 5,
        slidesPerView: 5,
      },
    },
  };

  const [matches, setMatches] = useState(0);
  const [users, setUsers] = useState(0);
  const [swipes, setSwipes] = useState(0);

  useEffect(() => {
    const getData = async () => {
      axios({
        method: "get",
        url: "https://services.aldealista.com/v1/admin/stats-min",
        params: {
          userEmail: "",
          userUID: "",
        },
      })
        .then((res) => {
          setMatches(res.data.totalMatches);
          setUsers(res.data.totalUsers);
          setSwipes(res.data.totalSwipes);
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getData();
  }, []);

  return (
    <>
      <section className="section bg-light" id="app">
        <div className="container mt-10">
          {/* MAIN */}
          <div style={{ background: `url(${Map}) center center` }}>
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6">
                <div className="position-relative me-lg-5">
                  <img
                    src={Mockup}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                    style={{ height: "500px" }}
                  />
                  <div className="play-icon">
                    <Link2
                      to="#"
                      onClick={() => setModal(true)}
                      data-type="youtube"
                      data-id="yba7hPeTSjk"
                      className="play-btn lightbox"
                    >
                      <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                    </Link2>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title">
                  <h4 className="title mb-3 main-color">
                    {t("app.headerTitle")}
                  </h4>
                  <h5 className="mb-3">{t("app.headerSubtitle")}</h5>
                  <p className="text-muted">{t("app.text1")}</p>
                </div>
              </div>
            </div>
          </div>

          {/* FEATURES */}
          <Col lg={12}>
            <div className="pt-5 mt-3 rounded shadow px-4 py-5">
              {/*<h4 className="title mb-5 text-center">Our Mind Power</h4>*/}

              <Row>
                <div>
                  <h4 className="title text-center mt-3 pt-5">
                    {t("app.featuresTitle")}
                  </h4>
                  <h5 className="title text-center mb-5 mt-3">
                    {t("app.featuresSubtitle")}
                  </h5>
                </div>
                <div className="col-lg-4 col-md-6 ">
                  <div className="d-flex features feature-primary flex-column">
                    <div className="feature-icon text-center d-flex justify-content-center pb-4">
                      <img
                        src={SVG1}
                        className="rounded img-fluid mx-auto d-block"
                        alt=""
                        style={{ width: "50px" }}
                      />
                    </div>
                    <div className="flex-1 ms-3 px-2">
                      <h5 className="mt-0">{t("app.featuresText1")}</h5>
                      <p className="text-muted mb-0">
                        {t("app.featuresSubtext1")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mt-4 pt-4 mt-sm-0 pt-sm-0 ">
                  <div className="d-flex features feature-primary flex-column">
                    <div className="feature-icon text-center d-flex justify-content-center pb-4">
                      <img
                        src={SVG2}
                        className="rounded img-fluid mx-auto d-block"
                        alt=""
                        style={{ width: "50px" }}
                      />
                    </div>
                    <div className="flex-1 ms-3 px-2">
                      <h5 className="mt-0">{t("app.featuresText2")}</h5>
                      <p className="text-muted mb-0">
                        {t("app.featuresSubtext2")}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 mt-4 pt-4 mt-lg-0 pt-lg-0 ">
                  <div className="d-flex features feature-primary flex-column">
                    <div className="feature-icon text-center d-flex justify-content-center pb-4">
                      <img
                        src={SVG3}
                        className="rounded img-fluid mx-auto d-block"
                        alt=""
                        style={{ width: "50px" }}
                      />
                    </div>
                    <div className="flex-1 ms-3 px-2">
                      <h5 className="mt-0">{t("app.featuresText3")}</h5>
                      <p className="text-muted mb-0">
                        {t("app.featuresSubtext3")}
                      </p>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Col>

          {/* METRICS */}
          <div className="container mt-5 mb-4 pb-5">
            <Row>
              <div>
                <h4 className="title text-center mt-3 pt-5">
                  {t("app.metrics")}
                </h4>
                <h5 className="title text-center mb-5 mt-3">
                  {t("app.metricsSubtitle")}
                </h5>
              </div>
              <div className="col-md-4 col-4">
                <div className="counter-box position-relative text-center">
                  <h3 className="mb-0 fw-semibold mt-2">
                    <CountUp
                      className="counter-value"
                      start={0}
                      end={users}
                      delay={5}
                    />
                  </h3>
                  <span className="counter-head text-muted">
                    {" "}
                    {t("app.users")}
                  </span>
                </div>
              </div>

              <div className="col-md-4 col-4">
                <div className="counter-box position-relative text-center">
                  <h3 className="mb-0 fw-semibold mt-2">
                    <CountUp
                      className="counter-value"
                      start={0}
                      end={matches}
                      delay={5}
                    />
                  </h3>
                  <span className="counter-head text-muted">
                    {t("app.matches")}
                  </span>
                </div>
              </div>

              <div className="col-md-4 col-4">
                <div className="counter-box position-relative text-center">
                  <h3 className="mb-0 fw-semibold mt-2">
                    <CountUp
                      className="counter-value"
                      start={0}
                      end={swipes}
                      delay={5}
                    />
                  </h3>
                  <span className="counter-head text-muted">
                    {t("app.swipes")}
                  </span>
                </div>
              </div>
            </Row>
          </div>

          <Row className="justify-content-center pt-5 mt-2">
            <Col>
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-3">{t("app.screenshotTitle")}</h4>
                <h5 className="mb-3">{t("app.screenshotSubtitle")}</h5>
              </div>
            </Col>

            {/* SCREENSHOTS */}
            <Col lg={12}>
              <Row>
                <div className="app-shot-one__carousel">
                  <Swiper className="app-carousel" {...swiperParams}>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App1} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App2} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App3} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App4} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App5} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App6} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App7} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App8} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App9} alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="single-app-image">
                        <img src={App10} alt="" />
                      </div>
                    </SwiperSlide>
                  </Swiper>
                  <div
                    id="appshot-paginations"
                    className="swiper-pagination d-flex justify-content-center align-items-center"
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </section>
      <ModalVideo
        channel="youtube"
        isOpen={videoModal}
        videoId="hYu7nLdLU3g"
        onClose={() => setModal(false)}
      />
    </>
  );
}
