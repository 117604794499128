import React, { useState } from "react";
import { Modal, Button, Row, Col, Image } from "react-bootstrap";

export const jobsGraus = [
  {
    image: "https://ponaragonentumesa.com/wp-content/uploads/2017/11/melsa.jpg",
    title: "Administrativo/a",
    description:
      "Requerimos una persona interesada en un puesto de jornada completa de 8h de mañanas para las oficinas de nuestra fábrica de embutidos. Buscamos una persona con experiencia previa en el ámbito de la contabilidad.\n\n\n\nLa función a desempeñar es la de administrativo en el área de contabilidad. Las principales tareas a realizar adicionales a las estricatmente contables son: Emisión de pedidos de compras, control de la recepción de mercancías, realización de inventario y control de existencias.\n\n\n\nTipo de puesto: Jornada completa\n\n\n\nBeneficios:\n\n-Cesta de navidad\n\n-Jornada intensiva en verano\n\n-Jornada intensiva los viernes\n\nHorario:\n\n-Turno de mañana\n\nPosibilidad de trasladarse/mudarse:\n\n-22430 Graus, Huesca: Desplazarse al trabajo sin problemas o planificar mudarse antes de comenzar a trabajar (Obligatorio)\n\nEducación:\n\n-FP Grado Superior (Obligatorio)\n\nExperiencia:\n\n-Contabilidad: 2 años (Obligatorio)\n\nUbicación del trabajo: Empleo presencial",
    url: "https://es.indeed.com/cmp/Embutidos-Artesanos-Melsa.s.l/jobs?jk=0c83816f6227d1bc&start=0&clearPrefilter=1",
  },
  {
    image: "https://ponaragonentumesa.com/wp-content/uploads/2017/11/melsa.jpg",
    title: "Dependiente/a de charcutería",
    description:
      "Ganas de trabajar en Graus en un puesto estable. Se valorará disponer de experiencia previa en temas de carnicería-charcutería y/o atención al cliente. Es muy importante la limpieza y orden para desempeñar correctamente el puesto de trabajo.\n\n\n\nTipo de puesto: -Jornada completa\n\nBeneficios:\n\n-Cesta de navidad\n\n-Opción a contrato indefinido\n\n-Uniforme proporcionado\n\nHorario:\n\n-Turno de mañana\n\n-Turno de tarde\n\nRequisito de idioma flexible:\n\n-Español no requerido\n\nRetribución complementaria:\n\n-Plus domingos y festivos\n\nPosibilidad de trasladarse/mudarse:\n\n-22430 Graus, Huesca: Desplazarse al trabajo sin problemas o planificar mudarse antes de comenzar a trabajar (Obligatorio)\n\nEducación:\n\n-ESO (Obligatorio)\n\nExperiencia:\n\n-comercio: 1 año (Deseable)\n\nUbicación del trabajo: Empleo presencial",
    url: "https://es.indeed.com/cmp/Embutidos-Artesanos-Melsa.s.l/jobs?jk=cbcfe7ba79aa8d79&start=0&clearPrefilter=1",
  },
  {
    image: "https://ponaragonentumesa.com/wp-content/uploads/2017/11/melsa.jpg",
    title: "Operario/a de paquetería y envasado",
    description:
      "Requerimos una persona interesada en un puesto de jornada completa de 8 horas de mañanas para nuestra fábrica de embutidos. Buscamos una persona con ganas de trabajar en un puesto estable. Las principales funciones a desempeñar son la paquetería; envasado, empaquetado y expedición de pedidos.\n\n\n\nSe valorará disponer de experiencia previa en temas de almacén y/o logísticos.\n\nTipo de puesto: -Jornada completa, Contrato indefinido\n\nBeneficios:\n\n-Cesta de navidad\n\n-Jornada intensiva en verano\n\n-Jornada intensiva los viernes\n\n-Opción a contrato indefinido\n\n-Programa de formación\n\n-Uniforme proporcionado\n\nHorario:\n\n-Turno de 8 horas\n\n-Turno de mañana\n\nPosibilidad de trasladarse/mudarse:\n\n-22430 Graus, Huesca: Desplazarse al trabajo sin problemas o planificar mudarse antes de comenzar a trabajar (Obligatorio)\n\nEducación:\n\n-Primaria (Obligatorio)\n\nExperiencia:\n\n-en trabajos relacionados con el ofertado: 2 años (Deseable)\n\n-Licencia/Certificación:\n\n\n\n-Carnet de conducir (Obligatorio)\n\nUbicación del trabajo: Empleo presencial\n\n\n\n",
    url: "https://es.indeed.com/cmp/Embutidos-Artesanos-Melsa.s.l/jobs?jk=278091ac2543f745&start=0&clearPrefilter=1",
  },
  {
    image: "https://aldealista.com/news/graus/jobs/infojobs.png",
    title: "Electricista",
    description:
      "Funciones\n\nEmpresa líder en gestión de servicios energéticos, necesita incorporar Oficiales electricistas/operarios de redes MT/BT en Graus.\n\nLas funciones a desempeñar:\n\nMantenimiento de la red de distribución de energía eléctrica MT/BT, disponibilidad horaria por servicio de averías y obra eléctrica nueva.\n\nIncorporación inmediata.\n\nSe ofrece\n\nIncorporación inmediata.\n\nSalario en base al convenio del metal",
    url: "https://www.infojobs.net/graus/electricista-graus-h-m/of-i9cea96a75c44799888cfbd74f4a7be?applicationOrigin=search-new&page=1&sortBy=PUBLICATION_DATE",
  },
  {
    image: "https://aldealista.com/news/graus/jobs/sne.jpg",
    title: "Personal de limpieza",
    description:
      "Urge incorporar a una persona para limpieza de un centro privado en Graus.\n\nContrato de sustitución, jornada parcial.\n\nHorario de lunes a viernes de 07:00 a 11:00 h.\n\nSe requiere 3 meses de experiencia.",
    url: "https://www.domestiko.com/empleo/oferta/personal-de-limpieza-j2kj/",
  },
  {
    image: "https://aldealista.com/news/graus/jobs/infojobs.png",
    title: "Operario/a de producción",
    description:
      "Buscamos operarios/as para nuestra planta de embotellado de Graus.\n\nLas personas seleccionadas formarán parte del área de producción donde realizarán las siguientes tareas:\n\nReetiquetado del producto\n\nGestión del orden del almacén con carretilla frontal eléctrica\n\nTareas en el área de producción: abastecimiento líneas, llenado de botellas\n\nMantenimiento de dispensadores, cafeteras (limpieza y puesta a punto)\n\naprovisionamiento de materias primas y retirada de producto terminado de la línea de envasado.\n\nSe ofrece:\n\nContrato en Régimen General en jornada completa.\n\nFormación Continua y desarrollo profesional dentro de la empresa.\n\nEstabilidad Laboral.",
    url: "https://www.infojobs.net/graus/electricista-graus-h-m/of-i9cea96a75c44799888cfbd74f4a7be?applicationOrigin=search-new&page=1&sortBy=PUBLICATION_DATE",
  },
];

const JobList = () => {
  return (
    <div
      className="horizontal-slider-container"
      style={{
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "10px",
        maxWidth: "650px",
        width: "100vw",
      }}
    >
      {jobsGraus.map((job, index) => (
        <JobItem key={index} job={job} />
      ))}
    </div>
  );
};

const JobItem = ({ job }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <div
        onClick={handleShow}
        style={{
          backgroundColor: "white",
          color: "black",
          padding: "10px",
          cursor: "pointer",
          borderBottom: "1px solid #a6c693",
        }}
      >
        <Row>
          <Col
            xs={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Image src={job.image} fluid style={{ width: "150px" }} />
          </Col>
          <Col xs={8}>
            <h5>{job.title}</h5>
            <p
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4, // Limit to 4 lines
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              dangerouslySetInnerHTML={{
                __html: job.description.replace(/\n/g, "<br />"),
              }}
            />

            <Button variant="primary" href={job.url} target="_blank">
              Saber más
            </Button>
          </Col>
        </Row>
      </div>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{job.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Image src={job.image} fluid className="mb-3" />
          <p
            dangerouslySetInnerHTML={{
              __html: job.description.replace(/\n/g, "<br />"),
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" href={job.url} target="_blank">
            Saber más
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobList;
