import React, { useRef, useState } from "react";
import { Col, Row, CardBody, Card } from "reactstrap";
import AwardsIMG from "../assets/images/awards.png";
import BookIMG from "../assets/images/book.png";
import Map from "../assets/images/map.png";
import "../languages/i18n.js";
import { useTranslation } from "react-i18next";
import Award1 from "../assets/images/award1.jpg";
import Award2 from "../assets/images/award2.jpg";
import Award3 from "../assets/images/award3.jpg";
import Award4 from "../assets/images/award4.jpg";
import Award5 from "../assets/images/award5.jpg";
import { Link as Link2 } from "react-router-dom";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

export default function Awards() {
  const { t } = useTranslation();

  const [videoModal, setModal] = useState(false);

  const awardsList = [
    {
      title: t("awards.awardTitle5"),
      content: t("awards.awardSubtitle5"),
      image: Award5,
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/trives/ourensana-aldealista-elegida-mejor-startup-espana/202405020327391291376.html",
    },
    {
      title: t("awards.awardTitle4"),
      content: t("awards.awardSubtitle4"),
      image: Award4,
      btnClass: "btn",
      url: "https://www.lavozdegalicia.es/noticia/ourense/san-xoan-de-rio/2023/12/02/premian-sostenibilidad-cooperativa-castanas-amarelante/0003_202312O2C1991.htm",
    },
    {
      title: t("awards.awardTitle3"),
      content: t("awards.awardSubtitle3"),
      image: Award3,
      btnClass: "btn",
      url: "https://elreferente.es/actualidad/arranca-1a-edicion-que-vivan-los-pueblos-programa-aceleracion-startups-impulsado-yoigo-transformar-economia-espana-rural/",
    },
    {
      title: t("awards.awardTitle2"),
      content: t("awards.awardSubtitle2"),
      image: Award2,
      btnClass: "btn",
      url: "https://www.laregion.es/articulo/trives/aldealista-caso-exito-basado-tinder/202311150600331255471.html",
    },
    {
      title: t("awards.awardTitle1"),
      content: t("awards.awardSubtitle1"),
      image: Award1,
      btnClass: "btn",
      url: "https://www.somoscomarca.es/articulo/actualidad/aldealista-candidata-european-technology-awards-categoria-app/20230704200311154068.html",
    },
  ];

  const contentRef = useRef(null); // Create a ref

  return (
    <>
      <section className="section bg-light pb-6 mb-4" id="awards">
        <div className="container mt-10">
          <div style={{ background: `url(${Map}) center center` }}>
            <div className="row align-items-center ">
              <div className="col-lg-6 col-md-6">
                <div className="position-relative me-lg-5">
                  <img
                    src={AwardsIMG}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                  <div className="play-icon">
                    <Link2
                      to="#"
                      onClick={() => setModal(true)}
                      data-type="youtube"
                      data-id="Sc0D8sX_0U0"
                      className="play-btn lightbox"
                    >
                      <i className="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
                    </Link2>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title">
                  <h4 className="title mb-3 main-color">
                    {t("awards.mainHeader")}
                  </h4>
                  <h5 className="mb-3">{t("awards.subheaderTitle")}</h5>
                </div>
              </div>
            </div>

            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title">
                  <h5 className="mb-3">{t("awards.subheaderTitle2")}</h5>
                  <p className="text-muted">{t("awards.subheaderText1")}</p>
                  <p className="text-muted">{t("awards.subheaderText2")}</p>
                  <p className="text-muted">
                    <strong>{t("awards.subheaderText3")}</strong>
                  </p>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div className="position-relative me-lg-5">
                  <img
                    src={BookIMG}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <Row ref={contentRef} className="pt-5 mt-4">
            {awardsList.map((blogPost, index) => (
              <Col lg={4} md={6} className="mt-4 pt-4 pt-10 pb-2">
                <Card className="blog blog-primary shadow rounded overflow-hidden border-0">
                  <div className="blog-image position-relative overflow-hidden">
                    <img
                      src={blogPost.image}
                      className="img-fluid"
                      alt=""
                      style={{
                        width: "100%",
                        height: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>

                  <CardBody
                    className="content p-0"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      maxHeight: "315px",
                      minHeight: "315px",
                    }}
                  >
                    <div className="pt-2 px-4"></div>

                    <div className="px-4 pb-4 pt-1" style={{ flexGrow: 1 }}>
                      <a
                        href={blogPost.url}
                        target="_blank"
                        rel="noreferrer"
                        className="h5 title text-dark d-block mb-0"
                        style={{
                          width: "100%",
                        }}
                      >
                        {blogPost.title}
                      </a>
                      <p
                        className="text-muted mt-2 mb-0"
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 5,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {blogPost.content}
                      </p>

                      <div
                        className="mt-3"
                        style={{ position: "absolute", bottom: "30px" }}
                      >
                        <a
                          href={blogPost.url}
                          target="_blank"
                          rel="noreferrer"
                          className="link text-dark"
                        >
                          {t("awards.readMore")}{" "}
                          <i className="uil uil-arrow-right"></i>
                        </a>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </section>
      <ModalVideo
        channel="youtube"
        isOpen={videoModal}
        videoId="Sc0D8sX_0U0"
        onClose={() => setModal(false)}
      />
    </>
  );
}
