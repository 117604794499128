import React, { useState } from "react";
import { Modal, Button, Row, Col, Image } from "react-bootstrap";
import { IonIcon } from "@ionic/react";
import { homeOutline, resizeOutline, pricetagOutline } from "ionicons/icons";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export const housesGraus = [
  {
    images: [
      { url: "https://aldealista.com/news/graus/houses/2/01.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/02.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/03.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/04.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/05.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/06.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/07.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/08.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/09.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/10.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/11.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/12.jpg" },
      { url: "https://aldealista.com/news/graus/houses/2/13.jpg" },
    ],
    url: "https://www.indomio.es/anuncios/95320751/?utm_source=lifull-connect&utm_medium=aggregator&utm_campaign=paid_sale&utm_content=desktop",
    address: "Plaza Mayor",
    contact: "",
    title: "Casa unifamiliar Venta, Graus",
    description:
      "Gran casa completa en el centro de Graus, bajo parcialmente reformado en una de las principales calles del casco antiguo. Planta de 140 metros cuadrados, con fachada delantera y trasera, muy cerca ayuntamiento y la Plaza Mayor.\n\nVilla muy bien comunicada, poco más de dos horas de Barcelona y una hora y media de Zaragoza.\n\nLa casa consta de planta baja, tres alzadas y bodega: la planta baja tiene entrada de escalera independiente y entrada a local por ambas calles, el local mantiene los antiguos y preciosos arcos de piedra originales, ideal para alquilar como comercio o bar restaurante; el resto de plantas empezaron a vaciarse con el proceso de rehabilitación. En la planta superior encontramos una preciosa terraza con fantásticas vistas.\n\nMúltiples posibilidades de compra, la casa tiene un proyecto ya visado por el ayuntamiento consistente en la construcción de 6 viviendas/apartamentos independientes. El precio es de la totalidad de edificio más el proyecto pero existe la posibilidad de negociar la compra del inmueble sin el local o realizar una permuta. A consultar tras la visita.\n\nIdeal para primera o segunda residencia muy cerca del pirineo aragonés.\n\nNo pierdan la oportunidad de tener una casa completa en el centro de Graus, vengan a verla.",
    size: "464",
    price: "110.000",
  },
  {
    images: [
      { url: "https://aldealista.com/news/graus/houses/7/01.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/02.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/03.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/04.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/05.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/06.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/07.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/08.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/09.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/10.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/11.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/12.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/13.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/14.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/15.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/16.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/17.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/18.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/19.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/20.jpg" },
      { url: "https://aldealista.com/news/graus/houses/7/21.jpg" },
    ],
    url: "https://www.fincasgraus.com/inmueble/chalet-adosado-en-venta-en-graus/21461877",
    address: "",

    contact: "974 546 536",
    title: "Chalet Adosado en venta en Graus",
    description:
      "Fantástico chalet a adosado en zona muy tranquila con vistas al rio Ésera dentro del centro urbano de Graus. Vivienda situada en una zona privilegiada, con todos los servicios disponibles a muy pocos minutos a pie.\n\nLa vivienda se distribuye en tres plantas con garaje y bodega. La planta baja se distribuye en zaguán de entrada, luminoso comedor con chimenea y acceso a jardín con terraza elevada, gran cocina, y aseo; La primera planta consta de cuatro dormitorios y dos baños completos (uno con ducha y lavadora y otro con bañera). La tercera planta bajo cubierta es un gran espacio diáfano con numerosas posibilidades y armarios de almacenaje.\n\nPor último, encontramos una planta -1 destinada a gran garaje para varios vehículos y habitación independiente tipo despensa. Desde este espacio se puede acceder directamente al jardín y a la preciosa bodega con su propio hogaril.\n\nLa casa es muy luminosa, lista para entrar a vivir, incluye mobiliario y electrodomésticos, muy bien distribuida, con calefacción de gas ciudad, cerramientos de pvc con doble cristal, suelo de parqué en planta baja, ideal para familias. Muy poco uso desde su construcción en 2002.\n\nCalle privada trasera con zonas verdes comunes a su alrededor. Uno de los complejos de casas mejor valorados de la localidad por su ubicación y comunidad.\n\nGraus es una localidad con muy buena comunicación, apenas a unos 30 km de campo y 45 minutos del valle de Benasque, a una hora de Vielha, 2 h de Barcelona, 1,5h de Zaragoza y una de Lleida.",
    size: "212",
    price: "347.000",
  },
];

const HouseList = () => {
  return (
    <div
      className="horizontal-slider-container"
      style={{
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "10px",
        maxWidth: "650px",
        width: "100vw",
      }}
    >
      {housesGraus.map((house, index) => (
        <HouseItem key={index} house={house} />
      ))}
    </div>
  );
};

const HouseItem = ({ house }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  // Prepare images for react-image-gallery
  const galleryImages = house.images.map((img) => ({
    original: img.url,
    thumbnail: img.url,
  }));

  return (
    <>
      {/* Summary Card */}
      <div
        onClick={handleShow}
        style={{
          backgroundColor: "white",
          color: "black",
          padding: "10px",
          cursor: "pointer",
          borderBottom: "1px solid #a6c693",
        }}
      >
        <Row>
          <Col
            xs={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={house.images[0].url} // Display the first image
              fluid
              style={{ width: "150px", height: "auto", objectFit: "cover" }}
            />
          </Col>
          <Col xs={8}>
            <h5>{house.title}</h5>
            <p>
              <IonIcon icon={resizeOutline} style={{ marginRight: 8 }} />
              {house.size} m²
            </p>
            <p>
              <IonIcon icon={pricetagOutline} style={{ marginRight: 8 }} />€
              {house.price}
            </p>
            <p
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 4, // Limit description to 4 lines
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              dangerouslySetInnerHTML={{
                __html: house.description.replace(/\n/g, "<br />"),
              }}
            />
          </Col>
        </Row>
      </div>

      {/* Modal with Full Details */}
      <Modal show={showModal} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{house.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Image Slider */}
          <ImageGallery
            items={galleryImages}
            showThumbnails={true}
            showPlayButton={false}
          />

          {/* Full Details */}
          <div style={{ marginTop: "20px" }}>
            <p>
              <IonIcon icon={homeOutline} style={{ marginRight: 8 }} />
              {house.address || "Address not provided"}
            </p>
            <p>
              <IonIcon icon={resizeOutline} style={{ marginRight: 8 }} />
              {house.size} m²
            </p>
            <p>
              <IonIcon icon={pricetagOutline} style={{ marginRight: 8 }} />€
              {house.price}
            </p>
            <p
              dangerouslySetInnerHTML={{
                __html: house.description.replace(/\n/g, "<br />"),
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" href={house.url} target="_blank">
            Saber más
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HouseList;
